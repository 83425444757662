import { isArray } from 'lodash';

export function generateVariants(apiVariant, keyValuePair = false) {
  const ids = [];
  const names = [];
  const values = [];
  apiVariant.forEach((variant) => {
    let combination = generateVariant(variant);
    for (var name in combination) {
      if (!names.includes(name)) {
        names.push(name);
        values.push([combination[name]]);
        ids.push([variant.id]);
      } else {
        const existingIndex = names.findIndex(
          (includedName) => name === includedName
        );
        if (!values[existingIndex].includes(combination[name])) {
          values[existingIndex].push(combination[name]);
        }
        ids[existingIndex].push(variant.id);
      }
    }
  });

  if (keyValuePair) {
    let keyValue = {};
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      keyValue[name] = values[i];
    }
    return keyValue;
  } else {
    return { ids, names, values };
  }
}

export function generateVariantName(variantCombination, variant_names) {
  let name = '';
  let name_object = {};
  variantCombination.forEach((variantValue, variantIndex) => {
    if (variantIndex > 0) {
      name = `${name} | ${variant_names[variantIndex]}: ${variantValue}`;
    } else {
      name = `${variant_names[variantIndex]}: ${variantValue}`;
    }
    name_object[variant_names[variantIndex]] = variantValue;
  });

  return { name, name_object };
}

export function generateVariant(variantCombination) {
  if (!variantCombination?.name) {
    return null;
  }
  let combination = {};
  // try {
  //   const variantJSON = JSON.parse(variant.details);
  //   combination = variantJSON.combination;
  // } catch {
  const combinationStrings = variantCombination.name.split(' | ');
  combinationStrings.forEach((combinationString) => {
    const combo = combinationString.split(': ');
    combination[combo[0]] = combo[1];
  });
  // }

  return combination;
}

/**
 * @param {array} arrayOfArrays
 * @returns Combination of array values from the given array of arrays
 */
export function generateCartesian(arrayOfArrays) {
  var r = [],
    max = arrayOfArrays.length - 1;
  function helper(arr, i) {
    for (var j = 0, l = arrayOfArrays[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(arrayOfArrays[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

export function filterApIVariant(variants) {
  if (!isArray(variants)) {
    return [];
  }
  return variants.filter((variant, pos) => {
    return (
      variants.findIndex((thisVariant) => variant.name === thisVariant.name) ==
      pos
    );
  });
}

export default {
  generateVariants,
  generateVariant,
  generateVariantName,
  generateCartesian,
  filterApIVariant,
};
