<template>
  <div>
    <AppInputFile
        class="product-media-dropzone"
        :accept="isMedia ? '.jpg,.jpeg,.png' : null"
        v-model="files"
    >
      <!-- Template for the file dropzone -->
      <template
          v-slot:display-file-template="{
        file,
        index,
        createThumbnail,
        removeFile,
      }"
      >
        <div class="product-media-dropzone-card border border-gray h-100">
          <div v-if="!file.delete && validateFile(index, file, removeFile)">
          <span
              v-if="isMedia"
              class="
              product-media-dropzone-card-primaryBtn
              m-1
              p-1
              text-body-3 text-white
            "
              @click="setPrimaryMedia(index)"
          >
            Make Primary
          </span>
            <AppIcon
                name="close"
                class="
              product-media-dropzone-card-removeIcon
              m-1
              rounded-circle
              text-body-3 text-white
            "
                @click="removeIcon(index, file, removeFile)"
            ></AppIcon>
          </div>
          <AppBtn
              v-else
              text
              color="white"
              class="w-100 product-media-dropzone-card-removeIcon-cancel"
              @click="deleteFileFromApi(index, false)"
          >
            Cancel Delete
          </AppBtn>
          <AppMediaViewer
              v-if="typeof file === 'string'"
              :src="file"
              :alt="file"
              width="100%"
              aspect-ratio="1/1"
          ></AppMediaViewer>
          <AppMediaViewer
              v-else-if="file.id > 0"
              :src="file.file"
              :alt="file.file"
              width="100%"
              aspect-ratio="1/1"
          ></AppMediaViewer>
          <img
              v-else-if="typeof file !== 'string' && file.type.includes('image')"
              :src="createThumbnail(file)"
              :alt="file.name"
              width="100%"
              style="aspect-ratio: 1/1"
          />
          <div
              v-else-if="typeof file !== 'string' && file.type.includes('video')"
              class="bg-gray-light-3 h-100"
          >
            <video
                :src="createThumbnail(file)"
                width="100%"
                style="aspect-ratio: 1/1"
            ></video>
          </div>
          <div
              v-else
              class="
            h-100
            w-100
            d-flex
            flex-column
            align-items-center
            justify-content-center
          "
          >
            <AppIcon name="document" class="text-info"></AppIcon>
            <p class="text-truncate w-100 p-3">{{ file.name }}</p>
          </div>
          <span
              v-if="isMedia && index === primaryFileIndex"
              class="
            product-media-dropzone-card-primaryMedia
            w-100
            text-info text-truncate
          "
          >
          Primary Media
        </span>
        </div>
      </template>
    </AppInputFile>
    <!-- File error -->
    <ActionModal
        v-model="fileError"
        state="error"
        :header="fileError ? fileError : ''"
        description=""
    >
    </ActionModal>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppInputFile from '@/shared/elements/AppInputFile.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import { differenceWith, isEqual } from 'lodash';
import AppBtn from '../../../../shared/elements/AppBtn.vue';
import ActionModal from '../../Modals/ActionModal.vue';

export default {
  name: 'ProductAddFileDropzone',

  components: {
    AppInputFile,
    AppIcon,
    AppMediaViewer,
    AppBtn,
    ActionModal,
  },

  mixins: [_appDefaultInput],

  props: {
    isMedia: { type: Boolean, default: false },
  },

  data() {
    return {
      primaryFileIndex: false,
      fileError: null,
      files: [],
      innerVal: [],
    };
  },

  watch: {
    primaryFileIndex() {
      this.setInnerVal();
    },
    files: {
      handler() {
        this.setInnerVal();
      },
      deep: true,
    },
    innerVal: {
      handler(val) {
        this.files = [...val.map((file) => (file?.id ? file : file?.file))];
      },
      deep: true,
    },
  },

  methods: {
    setPrimaryMedia(index) {
      this.primaryFileIndex = index;
    },
    setInnerVal() {
      const primaryIndex = this.primaryFileIndex;
      const isMedia = this.isMedia;
      const files = this.files;

      const newValue = files.map((file, index) => ({
        id: file?.id ? file.id : null,
        file: file?.id ? file.file : file,
        delete: file?.id ? file.delete : false,
        title: isMedia
          ? `Product Media ${index + 1}`
          : `Product Specification ${index + 1}`,
        type: isMedia
          ? primaryIndex === index
            ? 'primary_image'
            : 'extra_image'
          : 'spec_image',
      }));

      const hasNewValue =
        differenceWith(newValue, this.innerVal, isEqual).length > 0;
      const hasRemovedValue =
        differenceWith(this.innerVal, newValue, isEqual).length > 0;

      if (hasNewValue || hasRemovedValue) {
        this.innerVal = newValue;
      }
    },
    deleteFileFromApi(index, isDelete = true) {
      this.files[index].delete = isDelete;
    },
    removeIcon(index, file, removeFile) {
      typeof file.id === 'string' || typeof file.id === 'number'
        ? this.deleteFileFromApi(index)
        : removeFile(index);
    },
    validateFile(index, file, removeFile) {
      if (!file) {
        return false;
      }
      if (!file.name || !file.size) {
        return true;
      }
      const maxFileSize = 50; //50 mb
      const mimes = [ 'image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif', 'image/svg',
        'video/mp4', 'video/mov', 'video/wmv', 'video/avi', 'video/qt',
        'video/ogg', 'video/m3u8', 'video/ts', 'video/3gp'];
      if (file.size / 1024 / 1024 > maxFileSize) {
        this.fileError = `The file ${file.name} is too large. Maximum file size is ${maxFileSize}MB.`;
        removeFile(index);
        return false;
      }
      if (!mimes.includes(file.type)) {
        this.fileError = `The file "${file.name}" format is not supported. Try uploading file with format ${mimes.join(', ')}.`;
        removeFile(index);
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-media-dropzone {
  // min-height: 140px;
  &-card {
    position: relative;
    &-primaryBtn {
      background-color: rgba(0, 0, 0, 0.433);
      cursor: pointer;
      transition: opacity 0.13s;
      opacity: 0;
      position: absolute;
      left: 0;
      z-index: 10;
    }
    &-primaryMedia {
      position: absolute;
      left: 0;
      bottom: -25px;
    }
    &-removeIcon {
      background-color: rgba(0, 0, 0, 0.433);
      cursor: pointer;
      transition: opacity 0.13s;
      opacity: 0;
      position: absolute;
      right: 0;
      z-index: 10;

      &-cancel {
        background-color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
        transition: opacity 0.13s;
        opacity: 1;
        position: absolute;
        right: 0;
        z-index: 10;
        height: 100%;
      }
    }

    &:hover {
      .product-media-dropzone-card {
        &-removeIcon,
        &-primaryBtn {
          opacity: 1;
        }
      }
    }
  }
}
</style>
