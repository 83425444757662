<template>
  <AppCard header="Unit Types" @click="$listeners.click">
    <div class="p-3">
      <!-- Has Quantity Option Checkbox -->
      <AppInputCheckbox
        v-model="hasQuantityOptions"
        label="This product can be bought in bulk, bundle or per piece"
      ></AppInputCheckbox>
      <!-- Has Quantity Option Form -->
      <div v-if="hasQuantityOptions">
        <!-- Quantity Options (TO BE DONE) -->
        <div
          v-for="(variant, index) in options.names"
          :key="`product-quantity-option-${index}`"
          class="
            d-flex
            flex-column flex-md-row
            align-items-stretch align-items-md-center
            mb-4
          "
        >
          <!-- Quantity option dropdown -->
          <AppInputSelect
            v-model="options.names[index]"
            hideError
            label="Option"
            :items="[
              ...displayableOptions,
              ...getOptionData(options.names[index]),
            ]"
          >
            <!-- Add new Option -->
            <template v-slot:option-footer>
              <div v-if="!hasNewOption" class="mt-3">
                <AppBtn
                  text
                  prependIcon="add_filled"
                  class="pl-1"
                  @click="openNewOptionForm"
                >
                  Add new option
                </AppBtn>
                <span
                  v-if="responseMessage"
                  class="ml-3 d-block"
                  :class="{ 'text-success': !error, 'text-danger': error }"
                >
                  {{ responseMessage }}
                </span>
              </div>
              <div v-if="hasNewOption" class="mt-3 pl-1">
                <AppInput
                  v-model="newOptionName"
                  placeholder="Option Name e.g. Boxes"
                ></AppInput>
                <AppInput
                  v-model="newOptionNameShort"
                  placeholder="Short Name e.g. Box (optional)"
                ></AppInput>
                <div class="d-flex justify-content-end">
                  <AppBtn outline @click="hasNewOption = false">Cancel</AppBtn>
                  <AppBtn
                    class="ml-2"
                    :disabled="newOptionName.length === 0"
                    :loading="loading"
                    @click="addNewQuantityOption"
                    >Create</AppBtn
                  >
                </div>
              </div>
            </template>
          </AppInputSelect>
          <!-- Quantity option conversion -->
          <div class="mt-2 mt-md-0 p-md-1 d-inline-flex align-items-center">
            <span class="m-2 unit-type d-none d-md-block text-left mt-4">
              1 {{ getOptionData(options.names[index]).shortName }} =
            </span>
            <AppInput
              v-model="options.values[index]"
              hideError
              :label="`1 ${getOptionData(options.names[index]).shortName}`"
              labelClass="d-md-none"
              type="number"
              min="1"
              class="mt-md-1 mt-md-4 ml-md-2 mr-md-2"
            >
              <template v-slot:append-icon>
                <p class="m-0">units</p>
              </template>
            </AppInput>
          </div>
          <!-- Quantity option delete -->
          <AppIcon
            hoverable
            name="trash_can"
            hoverColor="danger"
            class="ml-auto mr-3 mt-2 ml-md-3 mt-md-3"
            @click="confirmDeleteOption(index)"
          ></AppIcon>
        </div>
        <!-- Add Quantity Option Btn -->
        <AppBtn
          v-if="displayableOptions.length > 0"
          color="white text-primary"
          prependIcon="add"
          @click="addOption"
        >
          Add Option
        </AppBtn>
      </div>
    </div>
    <ActionModal v-model="deleteModal" @confirm="deleteOption">
      <template v-slot:title
        >Are you sure you want to delete this type?</template
      >
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>
  </AppCard>
</template>

<script>
/* eslint-disable */
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import Products from '@/shared/api/Products';
import apiHandler from '@/shared/utils/apiHandler';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';

export default {
  name: 'ProductAddFormPurchaseQuantity',

  components: {
    AppCard,
    AppInput,
    AppInputCheckbox,
    AppInputSelect,
    AppBtn,
    AppIcon,
    ActionModal,
  },

  mixins: [_appDefaultInput],

  data() {
    return {
      innerVal: {
        has_quantity_option: 0,
        quantities: [],
      },
      hasQuantityOptions: false,
      options: {
        names: [],
        values: [],
      },
      hasNewOption: false,
      newOptionName: '',
      newOptionNameShort: '',
      loading: false,
      error: false,
      responseMessage: '',
      optionList: [],
      deleteModal: false,
      deleteModalId: null,
    };
  },

  watch: {
    innerVal: {
      handler(newVal) {
        this.initOptions(newVal);
      },
      deep: true,
    },
    options: {
      handler(newOptions) {
        this.optionsChanged(newOptions);
      },
      deep: true,
    },
    hasQuantityOptions: {
      handler(value) {
        if (!value) {
          this.options.names = [];
          this.options.values = [];
          this.innerVal.quantities = [];
          this.innerVal.has_quantity_option = 0;
        }
      },
      deep: true,
    }
  },

  computed: {
    displayableOptions() {
      let options = this.optionList;

      options = options.filter(
        (option) => !this.options.names.includes(option.value)
      );

      return options;
    },
  },

  methods: {
    addOption() {
      if (this.displayableOptions.length <= 0) {
        alert('Reached maximum quantity option');
        return;
      }

      const newOption = this.displayableOptions[0];

      this.options = {
        names: [...this.options.names, newOption.value],
        values: [...this.options.values, 1],
      };
    },
    async deleteOption() {
      const index = this.deleteModalId;

      if (index === null || index < 0) {
        return;
      }

      const quantity_id = this.options.names[index];
      const valueIndex = this.innerVal.quantities.findIndex(
        (quantity) => quantity.quantity_id === quantity_id
      );
      await Products.deleteQuantityOptionForProduct({
        quantity_id: this.innerVal.quantities[valueIndex].quantity_id,
        product_id: this.$route.params.id,
        conversion: this.innerVal.quantities[valueIndex].conversion,
      }).then(() => {
        this.options.names.splice(index, 1);
        this.options.values.splice(index, 1);
        this.innerVal.quantities.splice(valueIndex, 1);
      });
    },
    confirmDeleteOption(index) {
      this.deleteModal = true;
      this.deleteModalId = index;
    },
    getOptionData(optionName) {
      const option = this.optionList.find(
        (option) => option.value === optionName
      );
      return option || null;
    },
    openNewOptionForm() {
      this.hasNewOption = true;
      this.newOptionName = '';
    },
    async fetchQuantityOptions() {
      const quantityResponse = await Products.getQuantities();

      this.optionList = quantityResponse.data.map((option) => ({
        text: option.name,
        value: option.id,
        shortName: option.short_name,
      }));

      this.$emit('ready', 'quantityOptions');
    },
    async addNewQuantityOption() {
      this.loading = true;

      const newOption = {
        name: this.newOptionName,
        short_name: this.newOptionNameShort || this.newOptionName,
        symbol_name: '-',
      };

      const isDuplicate = this.optionList.some(
        (option) =>
          option.name === newOption.name ||
          option.shortName === newOption.short_name
      );

      if (isDuplicate) {
        this.responseMessage = 'Option must be unique!';
        this.loading = false;
        this.hasNewOption = false;
        this.error = 'Option must be unique!';
        return;
      }

      const sendNewOption = await apiHandler.send(
        Products.postQuantity,
        newOption
      );

      this.error = sendNewOption.hasError;

      if (sendNewOption.hasError) {
        this.responseMessage =
          'There was a problem with your request. Try again!';
      } else {
        const newOption = sendNewOption.data;
        this.optionList.push({
          text: newOption.name,
          value: newOption.id,
          shortName: newOption.short_name,
        });
        this.responseMessage = `${this.newOptionName} has been added to the options!`;
      }
      this.newOptionName = '';
      this.hasNewOption = false;
      this.loading = false;
    },
    initOptions(data) {
      this.hasQuantityOptions = Boolean(data.has_quantity_option);

      this.options = {
        names: this.innerVal.quantities.map((q) => q.quantity_id),
        values: this.innerVal.quantities.map((q) => q.conversion),
      };
    },
    optionsChanged(data) {
      const newOptionsMapped = {
        has_quantity_option: this.hasQuantityOptions,
        quantities: data.names.map((value, index) => ({
          name: this.getOptionData(data.names[index]).text,
          conversion: parseInt(data.values[index]),
          quantity_id: value,
        })),
      };

      this.$emit('purchaseOptionChange', newOptionsMapped);
    },
  },

  async mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);

    await this.fetchQuantityOptions();
  },
};
</script>

<style lang="scss" scoped>
.unit-type {
  min-width: 120px;
}
</style>
