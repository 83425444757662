<template>
  <AppCard header="PRODUCT MEDIA" @click="$listeners.click">
    <div class="p-3">
      <ProductAddFileDropzone
        isMedia
        v-model="innerVal"
        class="product-add-form-uploads"
      ></ProductAddFileDropzone>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import ProductAddFileDropzone from './ProductAddFileDropzone.vue';

export default {
  name: 'ProductAddFormMedia',

  components: { AppCard, ProductAddFileDropzone },

  mixins: [_appDefaultInput],

  data() {
    return {
      innerVal: [],
    };
  },

  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
  },
};
</script>

<style lang="scss" scoped></style>
