<template>
  <AppCard header="variants" @click="$listeners.click">
    <div class="p-3">
      <AppInputCheckbox
        v-model="hasVariant"
        label="This product has multiple options, like different sizes or colors"
      ></AppInputCheckbox>
      <div v-if="hasVariant">
        <div
          v-for="(variant, index) in variants.names"
          :key="`product-variants-${index}`"
          class="d-flex flex-column flex-md-row align-items-md-end mb-3"
        >
          <AppInputSelect
            v-model="variants.names[index]"
            hideError
            label="Option"
            class="mb-3 mb-md-0"
            :items="[
              ...displayableVariants,
              ...getVariantData(variants.names[index]),
            ]"
          ></AppInputSelect>
          <AppInputItems
            v-model="variants.values[index]"
            hideError
            delimiter=","
            class="ml-md-3 w-100"
            placeholder="Separate variant names by comma"
          ></AppInputItems>
          <AppIcon
            hoverable
            hoverColor="danger"
            name="trash_can"
            class="ml-auto mr-2 mt-3 mt-md-0 ml-md-4 mr-0 mb-md-2"
            @click="deleteProductVariant(index)"
          ></AppIcon>
        </div>
        <!-- Add Variant -->
        <AppBtn
          v-if="displayableVariants.length > 0"
          color="white"
          prependIcon="add"
          class="text-primary"
          @click="addProductVariant"
        >
          Add Option
        </AppBtn>
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInputItems from '@/shared/elements/AppInputItems.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import Products from '@/shared/api/Products';

export default {
  name: 'ProductAddFormVariants',

  components: {
    AppCard,
    AppInputSelect,
    AppInputCheckbox,
    AppBtn,
    AppInputItems,
    AppIcon,
  },

  mixins: [_appDefaultInput],

  props: {
    variantsOverride: { type: Object, default: null },
  },

  data() {
    return {
      innerVal: {
        has_variant: 0,
        // not included in the form. just for ease of access
        variantLists: [],
      },
      hasVariant: false,
      variants: {
        ids: [],
        names: [],
        values: [],
      },
      variantLists: [
        {
          value: 'Thickness',
          text: 'Thickness',
        },
        {
          value: 'Size',
          text: 'Size',
        },
        {
          value: 'Type',
          text: 'Type',
        },
        {
          value: 'Color',
          text: 'Color',
        },
      ],
    };
  },

  watch: {
    innerVal: {
      handler(value) {
        const hasVariant = value.has_variant === 1;
        if (hasVariant !== this.hasVariant) {
          this.hasVariant = hasVariant;
          this.variants = value.variantLists;
        }
      },
      deep: true,
    },
    hasVariant(value) {
      this.innerVal.has_variant = value ? 1 : 0;
      if (!value) {
        this.variants = { names: [], values: [] };
        this.innerVal = [];
      }
    },
    variants: {
      handler(val) {
        this.$emit('variantsChange', val);
      },
      deep: true,
    },
  },

  computed: {
    displayableVariants() {
      let variants = this.variantLists;

      variants = variants.filter(
        (variant) => !this.variants.names.includes(variant.value)
      );

      return variants;
    },
  },

  methods: {
    addProductVariant() {
      if (this.displayableVariants.length > 0) {
        const newVariant = this.displayableVariants[0];
        this.variants.names.push(newVariant.value);
      } else {
        alert('Reached maximum variant');
      }
    },
    async deleteProductVariant(index) {
      await Products.deleteVariants({
        ids: this.variants.ids[index],
      }).then(() => {
        this.variants.ids.splice(index, 1);
        this.variants.names.splice(index, 1);
        this.variants.values.splice(index, 1);
      });
    },
    getVariantData(variantName) {
      const variant = this.variantLists.find(
        (variant) => variant.value === variantName
      );
      return variant || null;
    },
  },

  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
  },
};
</script>

<style lang="scss" scoped></style>
