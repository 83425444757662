<template>
  <div
    class="app-input-file"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <!-- Dropzone Squate -->
    <div class="row p-1">
      <!-- Files to be displayed on upload -->
      <div
        v-for="(file, index) in innerVal"
        :key="`file-${index}-${file.name}`"
        class="mb-5"
        :class="single ? 'col-12' : 'col-6 col-md-3'"
      >
        <slot
          name="display-file-template"
          :file="file"
          :index="index"
          :createThumbnail="createThumbnail"
          :removeFile="removeFile"
        >
          <div class="app-input-file-fileCard border border-gray h-100">
            <AppIcon
              name="close"
              class="
                app-input-file-fileCard-removeIcon
                m-1
                rounded-circle
                text-body-3 text-white
              "
              @click="removeFile(index)"
            ></AppIcon>
            <AppMediaViewer
              v-if="typeof file === 'string'"
              :src="file"
              :alt="file"
              width="100%"
              aspect-ratio="1/1"
            ></AppMediaViewer>
            <img
              v-else-if="file.type.includes('image')"
              :src="createThumbnail(file)"
              :alt="file.name"
              width="100%"
              style="aspect-ratio: 1/1"
            />
            <div
              v-else-if="file.type.includes('video')"
              class="bg-gray-light-3 h-100"
            >
              <video
                :src="createThumbnail(file)"
                width="100%"
                style="aspect-ratio: 1/1"
              ></video>
            </div>
            <div
              v-else
              class="
                h-100
                w-100
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <AppIcon name="document" class="text-info"></AppIcon>
              <p class="text-truncate w-100 p-3">{{ file.name }}</p>
            </div>
          </div>
        </slot>
      </div>
      <div
        v-if="!single || (single && innerVal.length === 0)"
        class="mb-md-5"
        :class="[innerVal.length > 0 ? 'col-md-3' : 'col-12']"
        @click="$refs.inputFile.click()"
      >
        <slot name="dropzone-template">
          <div
            class="
              app-input-file-dropzone
              h-100
              border border-dashed border-gray
            "
          >
            <div
              class="
                p-1
                h-100
                text-center
                d-flex
                flex-column
                align-items-center
                justify-content-center
              "
            >
              <AppIcon name="image_view" class="text-info"></AppIcon>
              <p class="m-0 text-body-2 font-weight-5">
                Drop your {{ single ? 'file' : 'files' }} here, or
                <span class="text-info">Browse</span>
              </p>
              <p class="m-0 text-caption text-gray">
                Supports standard image and video formats
              </p>
            </div>
          </div>
        </slot>
      </div>
    </div>

    <!-- Input Elements -->
    <div class="app-input-file-inputs">
      <!-- Input that holds component's value -->
      <!-- <input v-model="innerVal" type="hidden" /> -->
      <!-- Input File that Actually takes the uploaded File to passed to component's value -->
      <input
        :multiple="!single"
        ref="inputFile"
        type="file"
        name="fields[assetsFieldHandle][]"
        class="d-none overflow-hidden absolute"
        @change="onChange"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppIcon from './AppIcon.vue';
import AppMediaViewer from './AppMediaViewer.vue';

export default {
  name: 'AppInputFile',

  components: { AppIcon, AppMediaViewer },

  mixins: [_appDefaultInput],

  props: {
    value: { type: Array, default: () => [] },
    single: { type: Boolean, default: false },
  },

  data() {
    return {
      innerVal: [], // Store our uploaded files
      filelist: [], // Store our uploaded files
    };
  },

  methods: {
    addFile(file) {
      this.innerVal.push(file);
    },
    removeFile(index) {
      this.innerVal.splice(index, 1);
    },
    onChange() {
      this.innerVal = [...this.innerVal, ...this.$refs.inputFile.files];
      // reset the value of the inputFile (allows next upload duplication)
      this.$refs.inputFile.value = [];
    },
    remove(i) {
      this.removeFile(i);
    },
    createThumbnail(file) {
      return URL.createObjectURL(file);
    },

    /**
     *  Dropzone Events
     */
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-info-light-5')) {
        event.currentTarget.classList.add('bg-info-light-5');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('bg-info-light-5');
    },
    drop(event) {
      event.preventDefault();
      const files = [...event.dataTransfer.files];
      this.innerVal = [...this.innerVal, ...files];
      // this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove('bg-info-light-5');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-input-file {
  &-dropzone {
    cursor: pointer;
  }

  &-fileCard {
    position: relative;
    &-removeIcon {
      background-color: rgba(0, 0, 0, 0.433);
      cursor: pointer;
      transition: opacity 0.13s;
      opacity: 0;
      position: absolute;
      right: 0;
      z-index: 10;
    }

    &:hover {
      .app-input-file-fileCard-removeIcon {
        opacity: 1;
      }
    }
  }
}
</style>
