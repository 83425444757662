<template>
  <div class="app-input" :style="{ '--input-hover-color': `var(--${color})` }">
    <label :for="name" class="app-input-label">
      <span>{{ label }}</span>
      <!-- Required Asterisk -->
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="app-input-container">
      <!-- Prepend Icon -->
      <div class="app-input-icon-prepend">
        <slot name="prepend-icon">
          <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
        </slot>
      </div>
      <!-- Input Element -->
      <div class="w-100 d-flex flex-wrap">
        <div class="d-inline-flex flex-wrap">
          <div
            v-for="(item, index) in innerVal"
            :key="`app-input-item-${item}-${index}`"
            class="
              p-0
              pl-1
              pr-1
              m-0
              mb-1
              ml-1
              bg-gray-dark-6
              text-body-2 text-white
              rounded
              d-inline-flex
              align-items-center
              justify-content-center
            "
            style="word-break: break-word"
          >
            <span>{{ item }}</span>
            <AppIcon
              hoverable
              size="12"
              name="close"
              @click="removeItem(index)"
            ></AppIcon>
          </div>
        </div>
        <div
          class="
            pl-1
            flex-shrink-1 flex-grow-1
            d-inline d-flex
            align-items-center
          "
        >
          <input
            v-model="innerInput"
            class="m-0"
            :type="type"
            v-bind="$attrs"
            :name="name"
            @focus="focus = true"
            @blur="focus = false"
            @keyup="checkValueLength"
          />
        </div>
      </div>
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
      <!-- Append Icon -->
      <div class="app-input-icon-prepend">
        <slot name="append-icon">
          <AppIcon v-if="appendIcon" :name="appendIcon"></AppIcon>
        </slot>
      </div>
    </div>
    <!-- Select Options -->
    <transition name="app-input-select-options">
      <ul
        v-show="selectFocus"
        class="app-input-select-options bg-white shadow-40 w-100"
      >
        <li
          class="app-input-select-option-search bg-white sticky-top"
          @click="preventClose"
        >
          <slot name="option-header"></slot>
        </li>
        <li
          v-if="searchable"
          class="app-input-select-option-search p-3 bg-white sticky-top"
          @click="preventClose"
        >
          <AppInput
            v-model="searchFilter"
            hideError
            color="black"
            prependIcon="search"
            type="text"
            class="bg-gray-light-3"
            :placeholder="searchPlaceholder"
          />
        </li>
        <li
          v-for="(item, index) in filteredItems || items"
          tabindex="0"
          :key="`app-input-select-option-${index}-${
            item.value || item.text || item
          }`"
          class="p-1 pl-3 pr-3"
          @click="selectItem(item)"
          @keypress="selectItem(item, true)"
        >
          <slot name="option-item" :item="item">
            <AppInputCheckbox
              :value="isSelected(item)"
              hideError
              class="d-inline-block"
              @click="selectItem(item)"
            ></AppInputCheckbox>
            <span class="pt-1 pb-1 d-inline-block">{{
              item.text || item.value || item
            }}</span>
          </slot>
        </li>
        <li
          class="app-input-select-option-footer p-1 bg-white position-sticky"
          style="bottom: 1px"
          @click="preventClose"
        >
          <slot name="option-footer"></slot>
        </li>
      </ul>
    </transition>
    <!-- Error Message -->
    <span v-if="!hideError" class="app-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
import AppInputCheckbox from './AppInputCheckbox.vue';
export default {
  name: 'AppInputItems',

  components: { AppIcon, AppInputCheckbox },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    value: { type: Array, default: () => [] },
    type: { type: String, default: 'text' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
    delimiter: { type: String, default: ' ' },
    items: { type: Array, default: () => [] },
    searchable: { type: Boolean, default: false },
    searchPlaceholder: { type: String, default: '' },
    allowDuplicates: { type: Boolean, default: false },
  },

  data() {
    return {
      focus: false,
      selectFocus: false,
      innerInput: '',
      // innerVal: [],
      deletePressedCounter: 0,
      multiple: true,
    };
  },

  computed: {
    filteredItems() {
      const searchFilter = this.searchFilter;
      if (searchFilter) {
        const newItems = this.items.filter((value) => {
          const item = value.text || value.value || value;
          return item
            .toString()
            .toLowerCase()
            .includes(searchFilter.toLowerCase());
        });
        return newItems;
      } else {
        return null;
      }
    },
    displayText() {
      if (this.innerVal) {
        const value = this.innerVal;
        const items = this.items;
        if (this.multiple) {
          const displayables = this.innerVal.map((val) => {
            const valueItem = items.find((itemVal) => {
              const itemValInner = itemVal.value || itemVal.text || itemVal;
              return itemValInner == val;
            });

            return valueItem.text || valueItem.val || valueItem;
          });
          if (displayables.length > 0) {
            return displayables;
          }
        } else {
          const displayable = this.items.find(
            (val) => val.value === value || val === value
          );
          if (displayable) {
            return displayable.text || displayable.value || displayable;
          }
        }
      }
      return this.multiple ? [] : '';
    },
  },

  watch: {
    focus(val) {
      if (val) {
        this.openOptions();
      }
    },
    innerInput(val) {
      let items = val.split(this.delimiter);
      let innerVal = this.innerVal;
      let allowDuplicates = this.allowDuplicates;
      if (items.length > 1) {
        items = items.filter(
          (value) =>
            !!value.replace(/\s+/g, '') &&
            (!innerVal.includes(value) || allowDuplicates)
        );
        this.innerVal = [...this.innerVal, ...items];
        this.innerInput = '';
      }
    },
  },

  methods: {
    checkValueLength() {
      if (this.innerInput.length <= 0 && this.innerVal.length > 0) {
        if (this.deletePressedCounter > 1) {
          const items = this.innerVal;
          this.innerInput = items.pop();
          this.innerVal = items;
          this.deletePressedCounter = 0;
        } else {
          this.deletePressedCounter += 1;
        }
      } else {
        this.deletePressedCounter = 0;
      }
    },
    openOptions() {
      this.selectFocus = true;
      setTimeout(() => {
        document.addEventListener('click', this.closeOptions, false);
      }, 1);
    },
    closeOptions() {
      if (this.focus) {
        return;
      }
      if (this.preventCloseFlag) {
        this.preventCloseFlag = false;
        this.selectFocus = true;
      } else {
        this.selectFocus = false;
        setTimeout(() => {
          document.removeEventListener('click', this.closeOptions, false);
        }, 0);
      }
    },
    preventClose() {
      this.preventCloseFlag = true;
      this.selectFocus = true;
    },
    selectItem(item) {
      this.preventClose();
      const newVal = item.value || item.text || item;

      if (!this.innerVal) {
        this.innerVal = [newVal];
      } else if (this.innerVal.includes(newVal)) {
        this.innerVal = this.innerVal.filter((item) => item !== newVal);
      } else {
        this.innerVal = [...this.innerVal, newVal];
      }
    },
    isSelected(item) {
      if (this.innerVal) {
        const itemVal = item.value || item.text || item;
        if (this.multiple) {
          return this.innerVal.findIndex((val) => val == itemVal) > -1;
        } else {
          // this.items.find((val) => {
          //   if (item.value) {
          //     return val.value === item.value;
          //   } else if (item.text) {
          //     return val.text === item.text;
          //   } else {
          //     return val === item;
          //   }
          // });
          // return this.innerVal.includes(itemVal);
          return this.innerVal === itemVal;
        }
      }
    },
    removeItem(index) {
      this.innerVal.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';
</style>
