<template>
  <AppCard header="price range" class="mb-3" @click="$listeners.click">
    <div class="p-2 pl-3 pr-3">
      <p
        v-if="innerVal.quantities.length > 0"
        class="m-0 mb-1 text-uppercase font-weight-5"
      >
        Per Piece
      </p>
      <div class="d-flex align-items-center">
        <AppInputCurrency
          v-model="innerVal.price_start"
          type="number"
          label="MIN"
          min="1"
        ></AppInputCurrency>
        <span class="m-2">-</span>
        <AppInputCurrency
          v-model="innerVal.price_end"
          label="MAX"
          type="number"
          :validations="[validation.min(innerVal.price_start)]"
          :min="innerVal.price_start"
        >
          <template v-slot:prepend-icon>
            <p class="m-0 ml-2 mr-1 text-center">$</p>
          </template>
        </AppInputCurrency>
      </div>
    </div>
    <div v-if="innerVal.quantities.length > 0 && !hasVariants">
      <div
        v-for="(quantity, index) in innerVal.quantities"
        :key="`price-range-quantity-option-${index}`"
        class="p-2 pl-3 pr-3"
      >
        <p class="m-0 mb-1 text-uppercase font-weight-5">
          Per {{ quantity.name }}
        </p>
        <div class="d-flex align-items-center">
          <AppInputCurrency
            v-model="innerVal.quantities[index].price_start"
            type="number"
            label="MIN"
            min="1"
          >
            <template v-slot:prepend-icon>
              <p class="m-0 ml-2 mr-1 text-center">$</p>
            </template>
          </AppInputCurrency>
          <span class="m-2">-</span>
          <AppInputCurrency
            v-model="innerVal.quantities[index].price_end"
            label="MAX"
            type="number"
            :validations="[
              validation.min(innerVal.quantities[index].price_start),
            ]"
            :min="innerVal.quantities[index].price_start"
          >
            <template v-slot:prepend-icon>
              <p class="m-0 ml-2 mr-1 text-center">$</p>
            </template>
          </AppInputCurrency>
        </div>
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import { min, max } from '@/shared/utils/validation';
import AppInputCurrency from '@/shared/elements/AppInputCurrency.vue';
export default {
  name: 'ProductAddFormPriceRange',

  components: { AppCard, AppInputCurrency },

  mixins: [_appDefaultInput],

  props: {
    quantityOptions: { type: Array, default: () => [] },
    hasVariants: { type: Boolean, default: false },
  },

  data() {
    return {
      validation: { min, max },
      /** FORM VALUES */
      innerVal: {
        price_start: 1,
        price_end: 1,
        price_fixed: 1,
        quantities: [],
      },
    };
  },
  watch: {
    'innerVal.quantities': {
      handler(val) {
        this.$emit('purchaseQuantityOptions', val);
      },
      deep: true,
    },
    quantityOptions(val) {
      this.innerVal.quantities = val.map((value) => {
        const existingQty = this.innerVal.quantities.find(
          (el) => el.name === value.name
        );

        if (existingQty) {
          return existingQty;
        }

        //Create new if doesnt exist
        return {
          ...value,
          price_start: 1,
          price_end: 1,
          price_fixed: 1,
        };
      })
    },
    // innerVal: {
    //   handler(val) {
    //     // per unit price check
    //     // if (parseInt(val.price_end) <= parseInt(val.price_start)) {
    //     //   this.innerVal.price_end = this.innerVal.price_start;
    //     //   this.innerVal.price_fixed = this.innerVal.price_start;
    //     // } else {
    //     //   this.innerVal.price_fixed = 0;
    //     // }
    //     // per quantities price check
    //     // val.quantities.forEach((quantity, i) => {
    //     //   if (parseInt(quantity.price_end) <= parseInt(quantity.price_start)) {
    //     //     this.innerVal.quantities[i].price_end =
    //     //       this.innerVal.quantities[i].price_start;
    //     //     this.innerVal.quantities[i].price_fixed =
    //     //       this.innerVal.quantities[i].price_start;
    //     //   } else {
    //     //     this.innerVal.quantities[i].price_fixed = 0;
    //     //   }
    //     // });
    //   },
    //   deep: true,
    // },
  },

  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
  },
};
</script>

<style lang="scss" scoped></style>
