<template>
  <div class="app-input" :style="{ '--input-hover-color': `var(--${color})` }">
    <label :for="name" class="app-input-label">
      <span>{{ label }}</span>
      <!-- Required Asterisk -->
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="app-input-container">
      <!-- Prepend Icon -->
      <div class="app-input-icon-prepend">
        <slot name="prepend-icon">
          <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
        </slot>
      </div>
      <!-- Input Element -->
      <textarea
        v-model="innerVal"
        v-bind="$attrs"
        :name="name"
        :type="type"
        :style="{ minHeight }"
        @focus="focus = true"
        @blur="focus = false"
      />
      <!-- Shadow text area element -->
      <textarea
        ref="textAreaHeightReference"
        v-model="innerVal"
        class="app-input-text-area-shadow"
      />
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
      <!-- Append Icon -->
      <div class="app-input-icon-prepend">
        <slot name="append-icon">
          <AppIcon v-if="appendIcon" :name="appendIcon"></AppIcon>
        </slot>
      </div>
    </div>
    <!-- Error Message -->
    <span v-if="!hideError" class="app-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
export default {
  name: 'AppInput',

  components: { AppIcon },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    type: { type: String, default: 'text' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
  },

  data() {
    return {
      focus: false,
      minHeight: '0',
    };
  },

  watch: {
    innerVal() {
      this.computeTextAreaHeight();
    },
  },

  methods: {
    computeTextAreaHeight() {
      this.minHeight = `${this.$refs.textAreaHeightReference.scrollHeight}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';

.app-input-text-area-shadow {
  position: absolute;
  max-height: 0;
  width: 0;
  pointer-events: none;
  opacity: 0;
  margin: 0;
}
</style>
