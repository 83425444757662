<template>
  <div class="app-input" :style="{ '--input-hover-color': `var(--${color})` }">
    <label v-if="label" :for="name" class="app-input-label" :class="labelClass">
      <span>{{ label }}</span>
      <!-- Required Asterisk -->
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="app-input-container">
      <!-- Prepend Icon -->
      <div class="app-input-icon-prepend">
        <slot name="prepend-icon">
          <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
          <p v-else class="m-0 ml-2 mr-1 text-center">$</p>
        </slot>
      </div>
      <!-- Input Element -->
      <input
        v-model="currencyVal"
        type="text"
        v-bind="$attrs"
        :name="name"
        @focus="focus = true"
        @blur="focus = false"
      />
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
      <!-- Append Icon -->
      <div class="app-input-icon-prepend">
        <slot name="append-icon">
          <AppIcon v-if="appendIcon" :name="appendIcon"></AppIcon>
        </slot>
      </div>
    </div>
    <!-- Error Message -->
    <span v-if="!hideError" class="app-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
export default {
  name: 'AppInput',

  components: { AppIcon },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    value: { type: [String, Number], default: '0.00' },
    type: { type: String, default: 'text' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    labelClass: { type: String, default: '' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
  },

  data() {
    return {
      focus: false,
      currencyVal: this.value.toLocaleString('en') || 0,
      // displayVal: '',
    };
  },

  watch: {
    innerVal(val) {
      let innerValFormatted = '';
      if (val) {
        innerValFormatted = val.toLocaleString('en');
      }
      if (innerValFormatted !== this.currencyVal) {
        this.currencyVal = innerValFormatted;
      }
    },
    currencyVal(val, oldval) {
      if (isNaN(parseFloat(val))) {
        this.innerVal = 0;
        this.currencyVal = 0;
        return;
      }
      const oldvalFormatted = parseFloat(
        oldval.toString().replace(/,/g, '')
      ).toLocaleString('en');

      if (val !== oldvalFormatted) {
        if (val) {
          let numVal = parseFloat(val.toString().replace(/,/g, ''));
          let currencyString = numVal.toLocaleString('en');
          const decimalPos = val.indexOf('.');
          if (decimalPos >= 0) {
            const decimalLength = val.substring(decimalPos).length - 1;
            if (decimalLength > 0) {
              currencyString = numVal.toLocaleString('en', {
                minimumFractionDigits: decimalLength || 1,
                maximumFractionDigits: 6,
              });
            } else {
              currencyString = `${currencyString}.`;
            }
          }
          this.currencyVal = currencyString;
          this.innerVal = numVal;
        } else {
          this.currencyVal = 0;
          this.innerVal = 0;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';
</style>
