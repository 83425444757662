export default {
  send: async function (apiRequest, ...rest) {
    try {
      const response = await apiRequest(...rest);
      return {
        ...response,
        hasError: !response.status.toString().includes(20),
      };
    } catch (e) {
      console.error('apiHandler.js', e);
      return { ...e, hasError: true };
    }
  },
};
