<template>
  <AppCard header="ORGANIZATION" @click="$listeners.click">
    <div class="p-3">
      <!-- Categories -->
      <AppInputSelect
        v-model="innerVal.categories"
        multiple
        searchable
        label="category"
        class="w-100"
        placeholder="Choose Category..."
        searchPlaceholder="Search Category"
        :items="categories"
        :validate="validate"
        :validations="[validation.required()]"
      >
        <!-- Add New Category Form -->
        <template v-slot:option-footer>
          <div v-if="!hasNewCategory" class="d-flex align-items-center">
            <AppBtn
              text
              prependIcon="add_filled"
              class="pl-1"
              @click="openNewCategoryForm"
            >
              Add new category
            </AppBtn>
            <span
              v-if="responseMessage"
              class="ml-3 text-success"
              :class="{ 'text-success': !error, 'text-danger': error }"
            >
              {{ responseMessage }}
            </span>
          </div>
          <div v-if="hasNewCategory" class="pl-1">
            <AppInput
              v-model="newCategoryName"
              placeholder="Category Name"
            ></AppInput>
            <div class="d-flex justify-content-end">
              <AppBtn outline @click="hasNewCategory = false">Cancel</AppBtn>
              <AppBtn
                class="ml-2"
                :disabled="newCategoryName.length === 0"
                :loading="loading"
                @click="addNewCategory"
                >Create</AppBtn
              >
            </div>
          </div>
        </template>
      </AppInputSelect>
      <!-- Tags -->
      <AppInputItems
        v-model="innerVal.tags"
        label="Tags"
        delimiter=","
        placeholder="e.g. road, metal, tools etc"
        class="w-100"
        :validations="[validation.required()]"
      ></AppInputItems>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppInputItems from '@/shared/elements/AppInputItems.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import Products from '@/shared/api/Products';
import AppInput from '@/shared/elements/AppInput.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import { required } from '@/shared/utils/validation';
import apiHandler from '@/shared/utils/apiHandler';
export default {
  name: 'ProductAddFormOrganization',

  components: { AppCard, AppInputItems, AppInputSelect, AppInput, AppBtn },

  mixins: [_appDefaultInput],

  props: {
    validate: { type: [String, Boolean], default: '' },
  },

  data() {
    return {
      loading: false,
      error: false,
      responseMessage: '',
      hasNewCategory: false,
      newCategoryName: '',
      categories: [],
      validation: { required },
      innerVal: {
        categories: [],
        tags: [],
      },
    };
  },

  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
    this.fetchCategories();
  },

  methods: {
    openNewCategoryForm() {
      this.hasNewCategory = true;
      this.newCategoryName = '';
    },
    async addNewCategory() {
      this.loading = true;
      const sendNewCat = await apiHandler.send(
        Products.postCategory,
        this.newCategoryName
      );

      this.error = sendNewCat.hasError;
      if (sendNewCat.hasError) {
        this.responseMessage =
          'There was a problem with your request. Try again!';
      } else {
        const newCategory = sendNewCat.data;
        this.categories.push({
          text: newCategory.category,
          value: newCategory.id,
        });
        this.responseMessage = `${this.newCategoryName} has been added to the categories!`;
      }
      this.newCategoryName = '';
      this.hasNewCategory = false;
      this.loading = false;
    },
    async fetchCategories() {
      this.categories = (await Products.getCategories()).data.map(
        (category) => ({
          text: category.category,
          value: category.id,
        })
      );
      this.$emit('ready', 'category');
    },
  },
};
</script>

<style lang="scss" scoped></style>
