<template>
  <LayoutVendor>
    <template v-slot:title>EDIT PRODUCT</template>
    <ProductForm :id="id"></ProductForm>
  </LayoutVendor>
</template>

<script>
import ProductForm from '@/components/Partial/Products/ProductForm/ProductForm.vue';
import LayoutVendor from '../../../../components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
export default {
  name: 'VendorProductEdit',
  components: { ProductForm, LayoutVendor },

  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>
