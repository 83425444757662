<template>
  <AppCard header="Inventory" @click="$listeners.click">
    <div class="p-3">
      <AppInput label="Global sku" v-model="innerVal.sku"></AppInput>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
export default {
  name: 'ProductAddFormInventory',

  components: { AppCard, AppInput },

  mixins: [_appDefaultInput],

  data() {
    return {
      innerVal: {
        sku: '',
      },
    };
  },

  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
  },
};
</script>

<style lang="scss" scoped></style>
