<template>
  <AppCard header="GENERAL INFO" @click="$listeners.click">
    <div class="p-3">
      <!-- Product Name -->
      <AppInput
        v-model="innerVal.name"
        label="Product Name"
        placeholder="Enter Product Name..."
        class="w-100"
        :validate="validate"
        :validations="[validation.required()]"
      ></AppInput>

      <!-- Product Description -->
      <AppInputTextArea
        v-model="innerVal.description"
        label="Description"
        placeholder="Describe your product..."
        class="w-100"
        :validate="validate"
        :validations="[validation.required()]"
      ></AppInputTextArea>

      <!-- Product Status -->
      <AppInputCheckbox
        v-model="rawStatus"
        label="Make this product live & searchable"
      ></AppInputCheckbox>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox.vue';
import AppInputTextArea from '@/shared/elements/AppInputTextArea.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import { required } from '@/shared/utils/validation';
export default {
  name: 'ProductAddFormGeneral',

  components: { AppCard, AppInput, AppInputCheckbox, AppInputTextArea },

  mixins: [_appDefaultInput],

  props: {
    validate: { type: [String, Boolean], default: '' },
  },

  data() {
    return {
      validation: { required },
      innerVal: {
        name: '',
        description: '',
        status: 'draft',
      },
      rawStatus: false,
    };
  },

  watch: {
    innerVal: {
      handler(val) {
        this.rawStatus = val.status === 'live';
      },
      deep: true,
    },
    rawStatus(val) {
      this.innerVal.status = val ? 'live' : 'draft';
    },
  },
  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
  },
};
</script>

<style lang="scss" scoped></style>
