<template>
  <div
    v-if="
      innerVal.variants &&
      innerVal.variants.length > 0 &&
      quantityOptionWithSingle.length > 0
    "
  >
    <template v-if="!loading">
      <AppCard
        v-for="(option, optionIndex) in quantityOptionWithSingle"
        :key="`variant-pricing-${optionIndex}`"
        header="variants, Inventory & pricing"
        class="mb-3"
      >
        <div class="p-3 text-wrap-normal">
          <div class="row mb-4 text-small">
            <div class="col-3">Variant</div>
            <div class="col-3 border border-dark fw-bold">
              Price per {{ option.name }}
            </div>
            <div class="col-3">Inventory</div>
            <div class="col-3">SKU</div>
          </div>
          <div
            v-for="(variant, index) in innerVal.variants.filter(
              (variant) =>
                variant.quantity_option === option.name ||
                (option.name === 'unit' && !variant.quantity_option)
            )"
            :key="`form-pricing-${optionIndex}-${index}`"
            :class="`form-pricing-${optionIndex}-${index}`"
            class="row mb-4 mb-md-3"
          >
            <div class="col-12 col-md-3 mb-3 mb-md-0 d-flex align-items-center">
              <div>
                <span class="mr-1 d-inline-block">
                  <span
                    v-for="(detail, index) in generateVariant(variant)"
                    :key="`variant-detial-${index}`"
                  >
                    {{ detail }} /
                  </span>
                  <!-- {{
                  JSON.parse(variant.details) +
                  Object.keys(JSON.parse(variant.details))
                    .map(function (key) {
                      return variant.details[key];
                    })
                    .join(' / ')
                }} -->
                </span>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex align-items-center">
              <AppInputCurrency
                v-model="variant.price_fixed"
                hideError
                labelClass="d-md-none"
                type="number"
                class="w-100"
                min="1"
              >
                <template v-slot:prepend-icon>
                  <p class="m-0 ml-2 mr-1 text-center">$</p>
                </template>
              </AppInputCurrency>
            </div>
            <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex align-items-center">
              <AppInput
                v-model="variant.stocks"
                hideError
                labelClass="d-md-none"
                label="Inventory"
                type="number"
                class="w-100"
                min="0"
              ></AppInput>
            </div>
            <div class="col-12 col-md-3 mb-2 mb-md-0 d-flex align-items-center">
              <AppInput
                v-model="variant.sku"
                hideError
                label="SKU"
                labelClass="d-md-none"
                class="w-100"
              ></AppInput>
            </div>
          </div>
        </div>
      </AppCard>
    </template>
    <template v-else>
      <AppLoading fillSpace></AppLoading>
    </template>
  </div>
  <div v-else>
    <AppCard header="variants, Inventory & pricing" class="mb-3">
      <div class="p-3" @click="$listeners.click">
        <div class="row mb-4 text-small">
          <div class="col-12 col-md-3">Inventory</div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3 d-flex align-items-center">
            <AppInput
              v-model="innerVal.stocks"
              hideError
              type="number"
              min="0"
            ></AppInput>
          </div>
        </div>
      </div>
    </AppCard>
  </div>
</template>

<script>
/**
 * If you're reading the flow of the code read this note.
 * You might be wondering why the fuck do I have to complicate this shit
 * When I could've used the v-model directly to the innerVal.
 * Well There is a stupid side effect that made me scratch the inner parts of my skull.
 * So basically assigning them v-model replicate the value to the rest of
 * the variants which i dont know why so I had to
 * "trick" vue how to think by swapping keys from the variants.
 */
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import { required } from '@/shared/utils/validation';
import { generateVariant } from '@/shared/utils/variantsHelper';
import AppInputCurrency from '@/shared/elements/AppInputCurrency.vue';
import {
  generateCartesian,
  generateVariantName,
} from '@/shared/utils/variantsHelper';
import { isEqual } from 'lodash';
import AppLoading from '@/shared/elements/AppLoading.vue';

export default {
  name: 'ProductAddFormVariantDetails',

  components: { AppCard, AppInput, AppInputCurrency, AppLoading },

  mixins: [_appDefaultInput],

  props: {
    quantityOption: { type: Array, default: () => [] },
    variantMap: { type: [Boolean, Object], default: false },
    sku: { type: String, default: '' },
  },

  data() {
    return {
      // magicVal: {},
      validation: { required },
      quantityOptionWithSingle: [],
      innerVal: {
        stocks: 0,
        variants: [],
      },
      rawStatus: false,
      loading: false,
    };
  },

  watch: {
    variantMap: {
      handler(value) {
        if (value && value.values && value.values.length > 0) {
          this.createPricingCombination();
        } else {
          this.innerVal.variants = [];
        }
      },
      deep: true,
    },
    quantityOption(val) {
      let quantityOption;
      quantityOption = [
        {
          name: 'unit',
          conversion: 1,
        },
        ...val,
      ];
      this.quantityOptionWithSingle = quantityOption;
    },
    quantityOptionWithSingle() {
      this.createPricingCombination();
    },
    // magicVal: {
    //   handler(value) {
    //     this.magicValueToActualValue(value);
    //   },
    //   deep: true,
    // },
    // innerVal: {
    //   handler(value) {
    //     // let newMagic = {};
    //     // for (var key in value.variants) {
    //     //   const variant = value.variants[key];
    
    //     //   newMagic[key] = {
    //     //     price_start: variant.map((variantObj) => variantObj.price_start),
    //     //     price_end: variant.map((variantObj) => variantObj.price_end),
    //     //     price_fixed: variant.map((variantObj) => variantObj.price_fixed),
    //     //     stocks: variant.map((variantObj) => variantObj.stocks),
    //     //     sku: variant.map((variantObj) => variantObj.sku),
    //     //   };
    //     // }
    //     // this.magicVal = newMagic;
    //     // if (Object.keys(value.variants).length > 0) {
    //     //   this.sumAllVariantStocks(value.variants);
    //     //   this.sumAllVariantPrices(value.variants);
    //     // }
    //   },
    //   deep: true,
    // },
  },

  methods: {
    generateVariant,
    isOptionUnit(quantity_option) {
      return quantity_option === 'unit' || !quantity_option;
    },
    createPricingCombination() {
      if (this.variantMap && this.variantMap.values.length > 0) {
        this.loading = true;
        const variants = this.variantMap;
        const variant_values = variants.values;
        const variant_names = variants.names;
        const global_sku = this.sku;
        const combinations =
          variant_values.length > 0 ? generateCartesian(variant_values) : [];

        const variantCombination = combinations.map((combination) => {
          let name = generateVariantName(combination, variant_names);

          return {
            name: name.name,
            details: JSON.stringify({ combination: name.name_object }),
            sku: global_sku,
            stocks: 0,
            price_fixed: 0,
            price_start: 0,
            price_end: 0,
          };
        });

        const oldVariants = this.innerVal.variants;

        let variantVal = [];

        setTimeout(() => {
          this.quantityOptionWithSingle.forEach((element) => {
            variantVal = [
              ...variantVal,
              ...variantCombination.map((vrnt, index) => {
                let existingVariant = null;

                if (Array.isArray(oldVariants)) {
                  existingVariant = oldVariants.find((oldVariant) => {
                    return (
                      isEqual(
                        generateVariant(oldVariant),
                        generateVariant(vrnt)
                      ) && oldVariant.quantity_option === element.name
                    );
                  });
                }

                if (!existingVariant && oldVariants[index]) {
                  vrnt.price_fixed = oldVariants[index].price_fixed;
                  vrnt.stocks = oldVariants[index].stocks;
                  vrnt.sku = oldVariants[index].sku;
                }

                return {
                  ...vrnt,
                  ...existingVariant,
                  quantity_option: element.name,
                };
              }),
            ];
          });

          // check if initial variant has more value than variant val
          // Need this condition because the user could have changed the variant before then
          if (this.variantMap && this.variantMap.values.length > 0) {
            this.innerVal.variants = variantVal;
          }
          this.loading = false;
        }, 10000);
      }
    },
    magicValueToActualValue(value) {
      if (Object.keys(value).length > 0) {
        let magicToVariants = {};
        for (const [key, data] of Object.entries(value)) {
          const length = data.price_fixed.length;
          magicToVariants[key] = [];

          for (let i = 0; i < length; i++) {
            const newData = {
              details: this.innerVal.variants[key][i].details,
              name: this.innerVal.variants[key][i].name,
              price_fixed: value[key].price_fixed[i],
              stocks: value[key].stocks[i],
              sku: value[key].sku[i],
              price_start: value[key].price_start[i],
              price_end: value[key].price_end[i],
            };

            if (this.innerVal.variants[key][i].id) {
              newData.id = this.innerVal.variants[key][i].id;
            }
            // magicToVariants[key].push(newData);
            const variantCopy = this.innerVal.variants[key][i];
            if (!this.hasSameProperty(variantCopy, newData)) {
              this.innerVal.variants[key][i] = newData;
            }
          }
        }
        // this.innerVal.variants = magicToVariants;
      }
    },
    hasSameProperty(obj1, obj2) {
      for (var key in obj1) {
        if (obj2[key]) {
          return false;
        } else if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
      return true;
    },
    sumAllVariantStocks(value) {
      let stocks = 0;
      value.forEach((variant) => {
        stocks += parseInt(variant.stocks);
      });
      this.innerVal.stocks = stocks;
    },
    sumAllVariantPrices(value) {
      let price_start = 0;
      let price_end = 0;

      value.forEach((variant) => {
        if (price_start > parseInt(variant.price_fixed) || price_start === 0) {
          price_start = variant.price_fixed;
        }
        if (price_end < parseInt(variant.price_fixed)) {
          price_end = variant.price_fixed;
        }
      });
      this.$emit('priceRange', { price_start, price_end });
      // return value || price_start + price_end;
    },
  },
  mounted() {
    // pre-map the input from parent component (if not mapped yet)
    this.$emit('input', this.innerVal);
  },
};
</script>

<style lang="scss" scoped></style>
