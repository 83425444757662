<template>
  <div
    class="
      product-add
      px-2
      pb-2
      pl-md-4
      pr-md-4
      h-100
      overflow-auto
      bg-gray-light-3
    "
  >
    <AppLoading v-if="id && !orderFetch" fillSpace></AppLoading>
    <div v-show="!id || orderFetch" class="row h-100 oveflow-auto">
      <!-- Guide -->
      <div class="col-12 col-md-3">
        <div class="sticky-top pt-3">
          <div
            class="
              product-add-guide-header
              d-flex
              flex-md-column
              justify-content-md-center
            "
          >
            <GoBackBtn :url="{name: 'ProductList'}" persistText>
              <span class="d-none d-md-block">Back to Product List</span>
              <h2 class="d-md-none font-weight-normal">ADD NEW PRODUCT</h2>
            </GoBackBtn>
          </div>
          <!-- Guide Labels -->
          <div class="mt-4 d-none d-md-block">
            <p
              v-for="(guideLabel, index) in guideLabels"
              :key="`guide-label-${index}`"
              class="mt-4"
              :class="{ 'text-primary': index === focusedFormCard }"
            >
              {{ guideLabel }}
            </p>
          </div>
        </div>
      </div>
      <!-- Add Product Form -->
      <AppInputForm
        v-model="formValid"
        class="product-add-form col-12 col-md-9"
        @validate="uploadProduct"
        v-slot="{ validate }"
      >
        <div class="pl-md-5 pr-md-5">
          <div class="m-0 pb-3 bg-gray-light-3 sticky-top">
            <!-- Action Btns -->
            <div
              class="
                d-flex
                pt-md-3
                flex-column flex-md-row
                justify-content-center justify-content-md-end
              "
            >
              <AppBtn
                v-if="!id"
                color="success"
                class="mr-md-3 mt-2"
                :disabled="loading"
                @click="productImport.modal = true"
              >
                IMPORT PRODUCT
              </AppBtn>
              <AppBtn
                v-if="id"
                outline
                class="mt-2"
                color="danger"
                :disabled="loading"
                @click="productDelete.confirm = true"
              >
                DELETE PRODUCT
              </AppBtn>
              <AppBtn
                class="mt-2"
                v-else
                outline
                :disabled="loading"
                @click="clearForm"
                >DISCARD</AppBtn
              >
              <AppBtn
                class="ml-md-3 mt-2"
                :loading="loading"
                type="submit"
                :disabled="id ? !isFormDirty : false"
              >
                {{ id ? 'SAVE CHANGES' : 'UPLOAD PRODUCT' }}
              </AppBtn>
            </div>
            <!-- Error Message -->
            <p
              v-if="innerError"
              class="
                m-0
                p-0
                mt-2
                text-small text-center text-md-right text-danger
              "
            >
              {{ innerError }}
            </p>
          </div>

          <div v-if="id" class="d-md-none m-0 pb-3 bg-gray-light-3">
            <AppBtn
              outline
              :name="form.isActive === 1 ? 'locked' : 'unlocked'"
              :disabled="form.status === 'draft' || loading"
              class="mt-2 w-100 text-secondary"
              @click="toggleProductActive(id, form.isActive === 0)"
            >
              {{ form.isActive === 1 ? 'DEACTIVATE' : 'ACTIVATE' }}
            </AppBtn>
            <AppBtn
              outline
              class="mt-2 w-100 text-primary"
              @click="$router.push({ name: 'ProductView', params: { id: id } })"
            >
              VIEW PRODUCT IN STORE
            </AppBtn>
          </div>
          <div>
            <!-- General Info -->
            <ProductAddFormGeneral
              v-model="form.generalInfo"
              class="mb-3"
              :validate="validate"
              @click="focusCard(0)"
            ></ProductAddFormGeneral>

            <!-- Media files -->
            <ProductAddFormMedia
              v-model="form.mediaFiles"
              class="mb-3"
              @click="focusCard(1)"
            ></ProductAddFormMedia>

            <!-- Organization -->
            <ProductAddFormOrganization
              v-model="form.organization"
              class="mb-3"
              :validate="validate"
              @click="focusCard(2)"
              @ready="(value) => cardReady.push(value)"
            ></ProductAddFormOrganization>

            <!-- Purchase Quantity -->
            <ProductAddFormPurchaseQuantity
              v-model="form.purchaseQuantityOptions"
              class="mb-3"
              @click="focusCard(3)"
              @purchaseOptionChange="purchaseOptionChange"
              @ready="(value) => cardReady.push(value)"
            ></ProductAddFormPurchaseQuantity>

            <!-- Price Range -->
            <ProductAddFormPriceRange
              v-model="form.priceRange"
              class="mb-3"
              :quantityOptions="form.purchaseQuantityOptions.quantities"
              :hasVariants="variantList.values && variantList.values.length > 0"
              @click="focusCard(4)"
              @purchaseQuantityOptions="purchaseQuantityOptions"
            ></ProductAddFormPriceRange>

            <!-- Inventory -->
            <ProductAddFormInventory
              v-model="form.inventory"
              class="mb-3"
              @click="focusCard(5)"
            ></ProductAddFormInventory>

            <!-- Variants -->
            <ProductAddFormVariants
              v-model="form.variants"
              class="mb-3"
              :overrideVariants="overrideVariants"
              @variantsChange="(variants) => (variantList = variants)"
              @click="focusCard(6)"
            ></ProductAddFormVariants>

            <!-- Variants, Inventory, Pricing -->
            <ProductAddFormVariantsDetail
              v-model="form.variantPricing"
              class="mb-3"
              :optionMap="
                form.purchaseQuantityOptions &&
                form.purchaseQuantityOptions.has_option === 1
                  ? optionList
                  : false
              "
              :variantMap="
                variantList && form.variants.has_variant === 1
                  ? variantList
                  : false
              "
              :sku="form.inventory.sku"
              :quantityOption="form.purchaseQuantityOptions.quantities"
              @click="focusCard(6)"
              @priceRange="overridePriceRange"
            ></ProductAddFormVariantsDetail>

            <!-- Specification Files -->
            <ProductAddFormSpecFiles
              v-model="form.specFiles"
              class="mb-3"
              @click="focusCard(7)"
            ></ProductAddFormSpecFiles>
          </div>
        </div>
      </AppInputForm>
      <!-- Confirm Delete Modal -->
      <ActionModal
        v-model="productDelete.confirm"
        state="delete"
        @confirm="deleteProduct"
      >
        <template v-slot:title>
          Are you sure you want to delete this product?
        </template>
        <template v-slot:description> Click "Confirm" to proceed. </template>
      </ActionModal>
      <!-- Delete Success Modal -->
      <SuccessModal
        v-model="productDelete.success"
        @confirm="$router.back()"
        :withDescription="false"
      >
        <template v-slot:title> PRODUCT DELETED! </template>
        <template v-slot:description></template>
      </SuccessModal>
      <!-- Confirm Add Modal -->
      <ActionModal v-model="productUpload.confirm" @confirm="uploadProduct">
        <template v-slot:title>
          {{
            `Are you sure you want to ${
              id ? 'update' : 'upload'
            }  this product?`
          }}
        </template>
        <template v-slot:description> Click "Confirm" to proceed. </template>
      </ActionModal>
      <!-- Add Success Modal -->
      <SuccessModal
        v-model="productUpload.succes"
        @confirm="id ? $router.go() : $router.back()"
      >
        <template v-slot:title>
          {{ `${id ? 'UPDATE' : 'UPLOAD'} PRODUCT SUCCESS!` }}
        </template>
        <template v-slot:description></template>
      </SuccessModal>

      <!-- Import Product Modal -->
      <AppModal v-model="productImport.modal" width="500px">
        <div class="p-3">
          <h4 class="text-uppercase text-center">Import Products</h4>
          <AppInputFile
            v-model="productImport.file"
            single
            accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ></AppInputFile>
          <div class="d-flex justify-content-end">
            <AppBtn
              text
              :loading="loading"
              @click="productImport.modal = false"
            >
              Cancel
            </AppBtn>
            <AppBtn class="ml-2" :loading="loading" @click="importProduct">
              Import
            </AppBtn>
          </div>
        </div>
      </AppModal>
      <SuccessModal v-model="updateSuccessModal" @confirm="handleSuccessModal">
        <template v-slot:description> Product has been updated. </template>
        <template v-slot:action-btn>
          <AppBtn class="d-block" @click="handleUpdateSuccess"
            >Back to Product List</AppBtn
          >
        </template>
      </SuccessModal>
      <SuccessModal v-model="productImport.success" @confirm="$router.back()">
        <template v-slot:description>
          Product(s) has been imported. Click "confirm" to proceed
        </template>
      </SuccessModal>
    </div>
  </div>
</template>

<script>
import ProductAddFormGeneral from './ProductFormGeneral.vue';
import ProductAddFormMedia from './ProductFormMedia.vue';
import ProductAddFormOrganization from './ProductFormOrganization.vue';
import ProductAddFormPurchaseQuantity from './ProductFormPurchaseQuantity.vue';
import ProductAddFormPriceRange from './ProductFormPriceRange.vue';
import ProductAddFormInventory from './ProductFormInventory.vue';
import ProductAddFormVariants from './ProductFormVariants.vue';
import ActionModal from '../../Modals/ActionModal.vue';
import ProductAddFormVariantsDetail from './ProductFormVariantsDetail.vue';
import ProductAddFormSpecFiles from './ProductFormSpecFiles.vue';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInputForm from '@/shared/elements/AppInputForm.vue';
import Products from '@/shared/api/Products';
import formDataHelper from '@/shared/utils/formDataHelper';
import variantsHelper from '@/shared/utils/variantsHelper';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import { cloneDeep, isEqual } from 'lodash';
import AppModal from '../../../../shared/elements/AppModal.vue';
import AppInputFile from '../../../../shared/elements/AppInputFile.vue';
import SuccessModal from '../../Modals/SuccessModal.vue';

const INITIAL_FORM_STATE = {
  generalInfo: {
    name: '',
    description: '',
    status: 'draft',
  },
  organization: {
    categories: [],
    tags: [],
  },
  purchaseQuantityOptions: {
    quantities: [],
    has_quantity_option: 0,
  },
  priceRange: {
    price_start: 1,
    price_fixed: 1,
    price_end: 1,
    quantities: [],
  },
  inventory: {
    sku: '',
  },
  variants: {
    has_variant: false,
    variantLists: [],
  },
  variantPricing: {
    stocks: 0,
    variants: [],
  },
  mediaFiles: [],
  specFiles: [],
  isActive: 1,
};

export default {
  name: 'ProductViewVendor',

  components: {
    AppBtn,
    AppInputForm,
    GoBackBtn,
    ProductAddFormGeneral,
    ProductAddFormMedia,
    ProductAddFormOrganization,
    ProductAddFormPurchaseQuantity,
    ProductAddFormPriceRange,
    ProductAddFormInventory,
    ProductAddFormVariants,
    ProductAddFormVariantsDetail,
    ProductAddFormSpecFiles,
    ActionModal,
    AppLoading,
    AppModal,
    AppInputFile,
    SuccessModal,
  },

  mixins: [_appApiHelper],

  props: {
    id: { type: [Number, String, Boolean], default: false },
  },

  data() {
    return {
      // Form Related Data
      formValid: false,
      qtyHasChanged: false,
      focusedFormCard: 0,
      createdId: null,
      cardReady: [],
      form: cloneDeep(INITIAL_FORM_STATE),
      formCapturedState: {},
      updateSuccessModal: false,

      // special for data variant
      overrideVariants: {},

      // not included in form
      orderFetch: false,
      deleteConfirm: false,
      deleteSuccess: false,
      uploadConfirm: false,
      uploadSuccess: false,
      innerError: false,
      variantList: {},
      optionList: {},
      quantities: {},
      isMappingOptions: false,
      guideLabels: [
        'General Information',
        'Product Media',
        'Organization',
        'Purchase Quantity Options',
        'Price Range',
        'Inventory',
        'Variants',
        'Files',
      ],
      productImport: {
        modal: false,
        file: null,
        success: false,
      },
      productUpload: {
        confirm: false,
        success: false,
      },
      productDelete: {
        confirm: false,
        success: false,
      },
    };
  },

  watch: {
    cardReady: {
      handler(value) {
        if (
          this.id &&
          value.includes('category') &&
          value.includes('quantityOptions')
        ) {
          this.fetchProductData();
        }
      },
      deep: true,
    },
    'form.variants': {
      handler(value) {
        if (value.has_variant === 0) {
          this.variantList = {};
        }
      },
      deep: true,
    },
  },

  computed: {
    isFormDirty() {
      return !isEqual(this.formCapturedState, this.form) || this.qtyHasChanged;
    },
  },

  methods: {
    handleUpdateSuccess() {
      this.$router.push({ name: 'ProductList' });
    },
    handleSuccessModal() {
      if (!this.id) {
        this.$router.push({
          name: 'ProductEdit',
          params: { id: this.createdId },
        });
      } else {
        this.handleUpdateSuccess();
      }
    },

    async toggleProductActive(id, is_active = false) {
      this.loading = true;

      const response = await Products.editProduct(id, {
        is_active: is_active ? 1 : 0,
      });

      if (response.status === 200) {
        await this.fetchProductData();
      }

      this.loading = false;
    },

    // compareQty() {
    //   let oldQty = this.formCapturedState.purchaseQuantityOptions;
    //   let newQty = this.form.purchaseQuantityOptions;

    //   if (!newQty || !oldQty || !newQty.quantities || !oldQty.quantities) {
    //     return false;
    //   }

    //   newQty = [...newQty.quantities];
    //   oldQty = [...oldQty.quantities];

    //   const mapper = (qty) => ({
    //     conversion: qty.conversion,
    //     name: qty.name,
    //     quantity_id: qty.quantity_id,
    //   });

    //   newQty = newQty.map(mapper);
    //   oldQty = oldQty.map(mapper);

    //   return !isEqual(oldQty, newQty);
    // },

    mapQtyOptions(options) {
      const mapper = (qty) => ({
        conversion: qty.conversion,
        name: qty.name,
        quantity_id: qty.quantity_id,
        price_start: 1,
        price_end: 1,
        price_fixed: 1,
      });

      this.$set(
        this.form.purchaseQuantityOptions,
        'has_quantity_option',
        options.has_quantity_option
      );
      this.$set(
        this.form.purchaseQuantityOptions,
        'quantities',
        options.quantities.map(mapper)
      );
    },

    purchaseOptionChange(options) {
      this.optionList = options;

      const mapper = (qty) => ({
        conversion: qty.conversion,
        name: qty.name,
        quantity_id: qty.quantity_id,
        price_start: 1,
        price_end: 1,
        price_fixed: 1,
      });

      //To avoid infinite loop mapping, only map when it changed
      if (
        !isEqual(
          options.quantities.map(mapper),
          this.form.purchaseQuantityOptions.quantities
        )
      ) {
        this.mapQtyOptions(options);
      }
    },

    purchaseQuantityOptions(quantities) {
      const formQuantities = this.form.purchaseQuantityOptions.quantities;
      for (let i = 0; i < quantities.length; i++) {
        quantities[i].conversion = formQuantities[i].conversion;
      }
      this.quantities = quantities;
    },

    clearForm() {
      this.form = cloneDeep(INITIAL_FORM_STATE);
    },

    focusCard(labelIndex) {
      this.focusedFormCard = labelIndex;
    },
    overridePriceRange({ price_start, price_end }) {
      this.form.priceRange.price_start = price_start;
      this.form.priceRange.price_end = price_end;
    },
    async fetchProductData() {
      const existProduct = (await Products.getProduct(this.id)).data;
      // general info
      this.form.generalInfo = {
        ...existProduct,
        status: existProduct?.status || 'draft',
      };
      // organization
      this.form.organization.categories = existProduct.categories.map(
        (cat) => cat.id
      );
      // debugger
      this.form.organization.tags = existProduct.tags.map((tag) => tag.name);

      const quantities = existProduct.quantities.map((quantity) => ({
        id: quantity.id,
        conversion: quantity.conversion,
        quantity_id: quantity.quantity_id,
        name: quantity.quantity.name,
        price_fixed: quantity.price_fixed,
        price_start: quantity.price_start,
        price_end: quantity.price_end,
      }));

      // quantity option
      this.form.purchaseQuantityOptions.has_quantity_option =
        existProduct.quantities.length > 0 ? 1 : 0;
      this.form.purchaseQuantityOptions.quantities = quantities;
      // price range
      this.form.priceRange.price_start =
        existProduct.price_start || existProduct.price_fixed;
      this.form.priceRange.price_end =
        existProduct.price_end || existProduct.price_fixed;
      this.form.priceRange.quantities = quantities;
      //Is Active
      this.form.isActive = existProduct.is_active;
      // inventor
      this.form.inventory.sku = existProduct.sku;
      // media files
      this.form.mediaFiles = existProduct.files
        .filter(
          (files) =>
            files.type === 'primary_image' || files.type === 'extra_image'
        )
        .map((file) => ({
          ...file,
          file: file.filename,
          id: file.id,
        }));
      // spec files
      this.form.specFiles = existProduct.files
        .filter((files) => files.type.includes('spec_'))
        .map((file) => ({
          ...file,
          file: file.filename,
        }));

      this.orderFetch = true;
      this.$nextTick(() => {
        // variant is set from here to prevent clashing with custom listeners
        // (which automatically resets variant prices)
        const variants = variantsHelper.generateVariants(existProduct.variants);
        // variants
        this.form.variants.has_variant =
          existProduct.variants.length > 0 ? 1 : 0;
        if (existProduct.variants.length > 0) {
          this.form.variants.variantLists = variants;
          // pricing, variant, inventory
          this.form.variantPricing = {
            stocks: existProduct.stocks,
            variants: existProduct.variants,
          };
        } else {
          this.form.variantPricing = {
            variants: {},
            stocks: existProduct.stocks,
          };
        }

        this.formCapturedState = cloneDeep(this.form);
      });
    },
    async uploadProduct() {
      this.innerError = '';

      if (!this.formValid) {
        this.innerError = 'Please Complete the form first';
        return;
      } else if (!this.productUpload.confirm) {
        return (this.productUpload.confirm = true);
      }

      const formValue = {
        ...this.form.generalInfo,
        ...this.form.organization,
        // temporary for category_id,
        category_id: this.form.organization.categories[0],
        ...this.form.priceRange,
        ...this.form.inventory,
        ...this.form.variants,
        stocks: this.form.variantPricing.stocks,
        variants: this.form.variantPricing.variants,
        is_active: this.form.isActive,
        files: [
          // upload file with no ID
          ...this.form.mediaFiles.filter((fileObject) => !(fileObject.id > 0)),
          ...this.form.specFiles.filter((fileObject) => !(fileObject.id > 0)),
        ],
      };
      const qtyMerged = [];
      for (let i = 0; i < this.quantities.length; i++) {
        const item = this.quantities[i];
        qtyMerged.push({
          ...this.form.priceRange.quantities.find(
            (qty) =>
              qty.quantity_id === item.quantity_id && qty.name === item.name
          ),
          ...item,
        });
      }
      formValue.quantities = qtyMerged;

      if (formValue.price_start === formValue.price_end) {
        formValue.price_fixed = formValue.price_start;
      }

      const formData = formDataHelper.generateFormData(formValue);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      if (this.id) {
        await Products.editProduct(this.id, formData, config);
        // Files to delete if any
        const deletedFiles = [
          ...this.form.mediaFiles.filter((fileObject) => fileObject.delete),
          ...this.form.specFiles.filter((fileObject) => fileObject.delete),
        ];
        for (let i = 0; i < deletedFiles.length; i++) {
          await Products.deleteFile(deletedFiles[i].id);
        }
      } else {
        const response = await Products.postProduct(formData, config);
        this.createdId = response.data.id;
      }
      this.updateSuccessModal = true;
      if (this.error) {
        this.innerError = this.error;
      }

      setTimeout(() => {
        this.handleSuccessModal();
      }, 2000);
    },
    async deleteProduct() {
      await Products.deleteProduct(this.id);
      if (this.error) {
        this.innerError = this.error;
      } else {
        this.productDelete.success = true;
      }
    },
    async importProduct() {
      const { file } = this.productImport;
      if (file.length > 0) {
        const formData = new FormData();
        formData.append('file', file[0]);
        const response = await Products.importProducts(formData);
        this.productImport.success = response.status === 200;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-add {
  &-guide-header {
    &-back {
      cursor: pointer;
    }
  }
}
</style>
